<script setup lang="ts">
const { $linker } = useNuxtApp()

const props = defineProps({
  to: {
    type: String,
    required: true
  }
})

const href = $linker.get(props.to)
</script>

<template>
  <a :href="href">
    <slot></slot>
  </a>
</template>
